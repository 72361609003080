import request from '@/utils/request.js';
/**
 * 获取用户基本信息
 * @returns {Promise}
 */
export function getUserBasicInfo() {
  return request({
    url: '/traffic-construction/getUserBasicInfo',
    method: 'get'
  });
};

/**
 * 修改用户基本信息
 * @returns {Promise}
 */
export function postUserInfo(data) {
  return request({
    url: '/traffic-construction/editUserBasicInf',
    method: 'post',
    data
  });
};

/**
 * 修改密码
 * @returns {Promise}
 */
export function resetPwd(data) {
  return request({
    url: '/traffic-construction/sysUser/resetPwd',
    method: 'post',
    data
  });
};
