<template>
  <el-dialog
    width="530px"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="80px" :model="paramsForm" :rules="rules">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="paramsForm.name" placeholder="请输入姓名" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="paramsForm.phone" placeholder="请输入手机号" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="所属机构" prop="orgIdList">
        <el-cascader
          v-model="paramsForm.orgIdList"
          :options="orgTree"
          :props="defaultProps"
          clearable
          :show-all-levels="false"
          style="width: 400px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="分配角色" prop="roleId">
        <el-select v-model="paramsForm.roleId" placeholder="请选择" style="width: 400px">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="履职岗位">
        <el-select
          v-model="paramsForm.posId"
          multiple
          @change="changepos($event)"
          placeholder="请选择"
          style="width: 400px"
        >
          <el-option v-for="item in configList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import { getOrgTree } from '@/api/common/index.js';
import { getRoleList, postUserInfo, getListConfig } from '@/api/general-user/user-management.js';

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (value.length === 0) {
        callback(new Error('请输入管理员手机'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机'));
      } else {
        callback();
      }
    };
    return {
      title: '新增',
      visible: false,
      confirmLoading: false,
      orgTree: [],
      defaultProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name'
      },
      roleList: [],
      paramsForm: {
        id: '',
        name: '',
        phone: '',
        orgIdList: [], // 绑定用
        orgId: '',
        tenantId: '', // 缓存拿
        appId: '', // 缓存拿
        tenantName: '', // 缓存拿
        roleId: '',
        posId: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符以内', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        orgIdList: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      parentList: {},
      configList: []
    };
  },
  created() {
    const { tenantId, tenantName } = getItem('tenantInfo');
    const { appId } = getItem('appInfo');

    this.paramsForm.appId = appId;
    this.paramsForm.tenantId = tenantId;
    this.paramsForm.tenantName = tenantName;
  },
  methods: {
    getListConfig() {
      getListConfig().then(res => {
        this.configList = res.data;
      });
    },
    async getOrgTreeData() {
      const res = await getOrgTree();
      this.orgTree = res.data;
    },
    getRoleListData() {
      getRoleList().then(res => {
        this.roleList = res.data;
      });
    },
    // 构造 父子节点 关系对象
    getParentList(tree) {
      tree.forEach(item => {
        if (item.id !== this.paramsForm.tenantId) {
          const pid = item.pid;
          const oid = item.id;
          this.parentList[oid] = pid;
        }
        if (item.children?.length) {
          this.getParentList(item.children);
        }
      });
    },
    // 通过子节点id, 找出所有上级 id
    findParentList(id, result = []) {
      const pid = this.parentList[id];
      if (pid !== this.paramsForm.tenantId) {
        result.push(pid);
        this.findParentList(pid, result);
      }

      return result;
    },
    // 通过 id 获取 pid 数组
    getPidValue(id) {
      this.getParentList(this.orgTree);
      const result = this.findParentList(id);
      result.reverse().push(id);
      this.paramsForm.orgIdList = result;
    },
    open(data) {
      this.visible = true;
      this.getRoleListData();
      this.getListConfig();
      this.getOrgTreeData().then(() => {
        if (data) {
          this.$nextTick(() => {
            this.getPidValue(data.orgId);
          });
        }
      });
      if (data) {
        this.title = '编辑';
        this.$nextTick(() => {
          /* 编辑回显 */
          this.paramsForm.id = data.id;
          this.paramsForm.name = data.name;
          this.paramsForm.phone = data.phone;
          this.paramsForm.roleId = data.roleId;
          if (data.posId === '') {
            this.paramsForm.posId = [];
          } else {
            this.paramsForm.posId = data.posId.split();
          }
        });
      } else {
        this.title = '新增';
      }
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.orgId = '';
      this.paramsForm.posId = '';
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const { orgIdList } = this.paramsForm;
        this.paramsForm.orgId = orgIdList[orgIdList.length - 1];
        this.onPostUserInfo(this.paramsForm);
      });
    },
    onPostUserInfo(params) {
      this.confirmLoading = true;
      if (typeof params.posId === 'object') {
        params.posId = params.posId.toString();
      }
      postUserInfo(params)
        .then(res => {
          const { id } = params;
          this.$message.success(`${id ? '编辑' : '新增'}用户成功！`);
          this.$emit('on-success', id);
          this.handleColse();
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    changepos(data) {
      let end = data.slice(-1);
      this.paramsForm.posId = end;
    }
  }
};
</script>
