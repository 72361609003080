/*
 * @Author: your name
 * @Date: 2021-12-28 16:49:13
 * @LastEditTime: 2021-12-29 17:19:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\user-management.js
 */
import request from '@/utils/request.js';
/**
 * 获取人员列表
 * @returns {Promise}
 */
export function getUserList(params) {
  return request({
    url: '/traffic-construction/getUserPage',
    method: 'get',
    params
  });
}

/**
 * 切换人员状态
 * @returns {Promise}
 */
export function toggleStatus(data) {
  return request({
    url: '/traffic-construction/enableOrFreezeUser',
    method: 'post',
    data
  });
}

/**
 * 获取角色下拉列表
 * @returns {Promise}
 */
export function getRoleList(params) {
  return request({
    url: '/traffic-construction/sysRole/list',
    method: 'get',
    params
  });
}

/**
 * 新增/编辑用户
 * @returns {Promise}
 */
export function postUserInfo(data) {
  return request({
    url: '/traffic-construction/addOrEditUserInfoWithPosId',
    method: 'post',
    data
  });
}

/**
 * 导出人员清单
 * @returns {Promise}
 */
export function exportUserList(params) {
  return request({
    url: '/traffic-construction/userExport',
    method: 'get',
    responseType: 'blob',
    params
  });
}
/**
 * 履职岗位
 * @returns {Promise}
 */
export function getListConfig(params) {
  return request({
    url: '/traffic-construction/sysPos/listConfig',
    method: 'get',
    params
  });
}
